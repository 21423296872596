body {
    background-color: #353A42;
}


.page-layout {
    display: flex;
    flex-wrap: nowrap;
}


.main-page-content {
    /*width: calc(100% - 313px);*/
    flex: 1;
    display: flex;
    align-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.details-content{
    padding: 20px;
    flex: 1;
    display: block;
    flex-direction: column;
    row-gap: 70px;
}

.left-section-header-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.white-color{
    color: white
}

.recording-icons{
    color: #077e99;
    font-size: 100px !important;
}

.logout{
    color: #077e99;
    font-size: 40px !important;
}


.list-item-texts{
    display: flex;
    justify-content: center;
    color: #077e99;
    font-weight: 900 !important;
    font-size: 15px !important;
}

.start-record-container,
.pause-and-stop-container{
    bottom: 0;
    position: absolute;
}

.details-heading{
    color: #01b2df;
}

.details-p{
    color: white;
    font-size: 20px;
    width: 50% !important;
    text-align: left;
}

.add-button-container{
    margin-bottom: 30px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
}
.add-button-container:hover {
    background-color: rgba(0, 0, 0, 0.2);

}


.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }
  
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }
  
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }
