/* Rename this file to Register.module.css and update the contents accordingly */
:root {
    --color1: #292f38;
    --color2: #526470;
    --color2Hover: #415059;
    --color3: #fff;
}

.main{
    width: 100vw;
    height: 100vh;
}
/* The rest of the styles remain unchanged, using locally scoped names */
.container {
    /* background-color: var(--color3);
    width: 700px;
    height: 400px;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); */
    
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    
}
@media (min-width: 576px) {
    .container {
      max-width: 540px
    }
  }
  
  @media (min-width: 768px) {
    .container {
      max-width: 720px
    }
  }
  
  @media (min-width: 992px) {
    .container {
      max-width: 960px
    }
  }
  
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px
    }
  }
  
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
  }
  
  @media (min-width: 576px) {
    .container, .container-sm {
      max-width: 540px
    }
  }
  
  @media (min-width: 768px) {
    .container, .container-sm, .container-md {
      max-width: 720px
    }
  }
  
  @media (min-width: 992px) {
    .container, .container-sm, .container-md, .container-lg {
      max-width: 960px
    }
  }
  
  @media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 1140px
    }
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
  }

  .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    background-color: var(--color2);
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: var(--color3);
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,0.125);
    border-radius: .25rem
  }

.container__svgs {
    background-color: var(--color2);
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container__svg1 {
    width: 200px;
    height: 200px;
    margin-bottom: 10px;
}

.container__svg2 {
    width: 170px;
    height: 18px;
}

.container__content {
    width: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container__form {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container__inputField {
    background-color: transparent;
    width: 220px;
    padding: 0 0 10px 5px;
    font-size: 15px;
    letter-spacing: 1px;
    border: none;
    border-bottom: solid 1px var(--color2);
    border-radius: 0;
}

.container__inputField:first-of-type {
    margin-bottom: 35px;
}

.container__inputField:nth-of-type(2) {
    margin-bottom: 40px;
}

.container__inputField:focus-visible {
    border-bottom: solid 2px var(--color2Hover);
    outline: none;
}

.container__sumbitButton {
    background-color: var(--color2);
    color: var(--color3);
    width: 220px;
    padding: 15px 0;
    font-size: 14px;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    border-radius: 50px;
}

.container__sumbitButton:hover {
    background-color: var(--color2Hover);
}

.container__links {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
}

.container__link {
    color: var(--color2);
    font-size: 12px;
    text-decoration: none;
}

.container__link:hover {
    text-decoration: underline;
}

.container__separator {
    background-color: var(--color2);
    width: 1px;
    height: 10px;
    margin: 0 15px;
}
.black{
    background-color: var(--color2);
}